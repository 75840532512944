import { reactive } from "vue";
import axios from 'axios';

// Create a reactive store object
const store = reactive({
  chartOfAccounts: [],

  async addChartOfAccount(account) {
    // console.log(account)
    // store.chartOfAccounts.push(account);
    try {
        await axios.post(`${process.env.VUE_APP_AUTHDOMAIN}/api/v1/chart-of-accounts`, account);
        store.chartOfAccounts.push(account);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
  },

  editChartOfAccount(index, updatedAccount) {
    store.chartOfAccounts.splice(index, 1, updatedAccount);
  },
});

// Export the store object
export default store;